/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ScatterplotLayer, FlyToInterpolator } from "deck.gl"
import { easeBackInOut } from "d3-ease"

import DeckGLMap from "../maps/deckgl/deckgl-map"
import MinimalFooter from "../layout/minimal-footer"
import logo from "../../images/logo.svg"
import logoWhite from "../../images/logo-white.svg"

import "./splash-animation.css"

import { LIGHT_MINIMALIST, DARK_MINIMALIST } from "../../utils/constants"

const INITIAL_VIEW_STATE = {
  zoom: 6,
  pitch: 60,
  bearing: -30,
}

const INITIAL_VIEW_STATE_FINAL = {
  latitude: 20,
  longitude: 0,
  zoom: 0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 4000,
  transitionInterpolator: new FlyToInterpolator(),
}

const SPLASH_DURATION = 17000
const LAYER_TYPE = "scatterplot"
const splashAnimation = { enterProgress: 0, duration: 3000 }

const SplashAnimation = ({ setSplashPlayed }) => {
  const [colorMode] = useColorMode()
  const [showLayer, setShowLayer] = useState(false)
  const [viewState, setViewState] = useState(null)
  const data = useStaticQuery(graphql`
    {
      allCitiesJson {
        edges {
          node {
            latitude
            longitude
          }
        }
      }
      site {
        siteMetadata {
          title
          company
          url
        }
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => {
      setSplashPlayed(true)
    }, SPLASH_DURATION)
  }, [])

  const onLoadMap = () => {
    setShowLayer(true)

    setTimeout(() => {
      setViewState(INITIAL_VIEW_STATE_FINAL)
    }, 1500)
  }

  const onViewStateChange = state => {
    setViewState(state.viewState)
  }

  const renderLayers = () => {
    return [
      new ScatterplotLayer({
        id: "splash-animation-layer",
        data: data.allCitiesJson.edges,
        getPosition: d => [d.node.longitude, d.node.latitude],
        getFillColor:
          colorMode === "light"
            ? showLayer
              ? [227, 11, 92]
              : [255, 255, 255, 0]
            : showLayer
            ? [60, 220, 255]
            : [0, 0, 0, 0],
        // colorMode === "light" ? [227, 11, 92] : [60, 220, 255],
        getRadius: showLayer ? (viewState ? 30000 / viewState.zoom : 5000) : 50,
        transitions: {
          getFillColor: {
            duration: splashAnimation.duration,
          },
          getRadius: {
            duration: splashAnimation.duration,
            easing: easeBackInOut,
          },
        },
      }),
    ]
  }

  return (
    <>
      <div
        className="splash-wrapper-initial"
        sx={{
          position: "absolute",
          zIndex: 950,
          top: 0,
          width: "100%",
          height: "100%",
          flexDirection: "column",
          backgroundColor: colorMode === "light" ? "white" : "black",
        }}
      />
      <div
        className="splash-wrapper-final"
        sx={{
          position: "absolute",
          zIndex: 850,
          top: 0,
          width: "100%",
          height: "100%",
          flexDirection: "column",
          backgroundColor: colorMode === "light" ? "white" : "black",
        }}
      />
      <div
        className="splash-animation-wrapper"
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // backgroundColor: colorMode === "light" ? "white" : "black",
          bg: colorMode === "dark" ? "#242424" : "#b4b9b9",
          zIndex: 900,
        }}
      >
        <img
          src={colorMode === "light" ? logo : logoWhite}
          alt="Logo"
          sx={{
            position: "absolute",
            zIndex: 1000,
            top: "5%",
            left: ["inherit", "5%", "5%", "5%"],
            width: [100, 150, 150, 150],
          }}
        />
        <h1
          sx={{
            variant: "styles.splashMessage",
          }}
        >
          <span className="splash-message-part-1">GeoDB,</span>
          <br />
          <span className="splash-message-part-2">
            a global Big Data marketplace
          </span>
          <br />
          <span className="splash-message-part-3">which rewards you</span>
          <br />
          <span className="splash-message-part-4">
            for the data you generate
          </span>
        </h1>
        <DeckGLMap
          mapType={LAYER_TYPE}
          layers={renderLayers()}
          onLoadMap={onLoadMap}
          extendedInitialView={INITIAL_VIEW_STATE}
          viewState={viewState}
          onViewStateChange={onViewStateChange}
          darkBase={DARK_MINIMALIST}
          lightBase={LIGHT_MINIMALIST}
        />
      </div>

      <MinimalFooter
        companyName={data.site.siteMetadata.company}
        companyUrl={data.site.siteMetadata.url}
      />
    </>
  )
}

export default SplashAnimation
