/** @jsx jsx */
import { jsx } from "theme-ui"

const MinimalFooter = ({ companyUrl, companyName }) => {
  return (
    <footer className="splash-footer" sx={{ variant: "styles.footerSplash" }}>
      Copyright © {new Date().getFullYear()},{" "}
      <a
        sx={{ variant: "styles.footerLink" }}
        href={companyUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {companyName}
      </a>
    </footer>
  )
}

export default MinimalFooter
