/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
// import React from "react"
import { Link } from "gatsby"
import { Card, Image } from "@theme-ui/components"
// import ImageLight from "../../images/map-light.png"
// import ImageDark from "../../images/map-dark.png"
// import MapImg from "../../images/map.png"
// import CityImg from "../../images/city.png"
// import DashboardImg from "../../images/dashboard.png"

export default function LinkGridList({ links }) {
  const [colorMode] = useColorMode()

  return (
    <ul sx={{ variant: "styles.homeLinks" }}>
      {links.map(link => (
        <li
          key={link.id}
          // sx={
          //   {
          //     // width: "80%",
          //     // py: [4, "initial", "initial", "initial"],
          //   }
          // }
        >
          <Link
            to={link.path}
            sx={{
              variant: "styles.noDecoration",
            }}
          >
            <Card
              sx={{
                variant: "styles.card",
              }}
            >
              <Image
                src={require(`../../images/${
                  colorMode === "light" ? link.imgLight : link.imgDark
                }`)}
                // width="70%"
                sx={{
                  // width: ["70%", "80%", "80%", "70%"],
                  width: "100%",
                  // minWidth: "250px",
                  // border:
                  //   colorMode === "light"
                  //     ? "2px solid #d6d6d6"
                  //     : "2px solid #292929",
                  border:
                    colorMode === "dark"
                      ? "2px solid white"
                      : "2px solid black",
                  // ? "3px solid #A6D61D"
                  // : "3px solid #F5B229",
                }}
              />
              <div
                sx={{
                  variant: "styles.cardTitle",
                  alignItems: "left",
                }}
              >
                {link.name}
              </div>
              {/* <Text sx={{ variant: "styles.cardDescription" }}>
                {link.description}
              </Text> */}
            </Card>
          </Link>
        </li>
      ))}
    </ul>
  )
}
