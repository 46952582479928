export const links = [
  {
    id: "activity",
    name: "Activity",
    path: "/activity",
    imgLight: "home/activity-light.png",
    imgDark: "home/activity-dark.png",
  },
  {
    id: "smart-city-demo",
    name: "Stories",
    path: "/stories/smartcities",
    // path: "/stories/smartcities-large-scale",
    imgLight: "home/stories-light.png",
    imgDark: "home/stories-dark.png",
  },
  {
    id: "total-data-metrics",
    name: "Metrics",
    path: "/",
    // path: "/metrics/total-data",
    imgLight: "home/metrics-light.png",
    imgDark: "home/metrics-dark.png",
  },
]

// export const activityLinks = [
//   {
//     value: "last-1h",
//     title: "Last Hour Activity",
//     url: "/activity/last-1h",
//   },
//   {
//     value: "last-24h",
//     title: "Last 24 Hour Activity",
//     url: "/activity/last-24h",
//   },
//   {
//     value: "user-footprint",
//     title: "User initial footprint",
//     url: "/activity/user-footprint",
//   },
//   {
//     value: "fake-activity",
//     title: "Fake Activity",
//     url: "/activity/fake-activity",
//   },
// ]

// export const storyLinks = [
//   {
//     value: "smartcities-large-scale",
//     title: "SmartCities: Large scale",
//     url: "/stories/smartcities-large-scale",
//   },
//   {
//     value: "smartcities-medium-scale",
//     title: "SmartCities: Medium scale",
//     url: "/stories/smartcities-medium-scale",
//   },
//   {
//     value: "smartcities-local-scale",
//     title: "SmartCities: Local scale",
//     url: "/stories/smartcities-local-scale",
//   },
// ]
