/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import GlobalContext from "../context/global-context"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import SplashAnimation from "../components/animations/splash-animation"
import LinkGridList from "../components/widgets/link-grid-list"
import { links } from "../utils/constants/links"

const IndexPage = () => {
  return (
    <GlobalContext.Consumer>
      {({ splashPlayed, setSplashPlayed }) => (
        <Layout>
          <SEO title={"Home"} />
          {!splashPlayed && (
            <SplashAnimation setSplashPlayed={setSplashPlayed} />
          )}
          <div sx={{ variant: "styles.content" }}>
            <div sx={{ variant: "styles.homeTitle" }}>
              GeoDB Big Data Dashboard
            </div>
            <p sx={{ variant: "styles.homeText" }}>
              Explore our data visualization tools, analise global statistics
              and metrics, and enjoy some of the features that the GeoDB
              ecosystem can offer.
            </p>
            <LinkGridList links={links} />
          </div>
        </Layout>
      )}
    </GlobalContext.Consumer>
  )
}

export default IndexPage
